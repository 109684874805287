<template>
	<div>
		<p>User Profile Settings</p>
	</div>
</template>

<script>
export default {
	name: 'ProfileSettings'
}
</script>
